import { WHATSAPP } from '../../constants/socials';

export const WhatsappButton = () => {
  return (
    <a
      href={WHATSAPP}
      target="_blank"
      rel="noreferrer"
      className="fixed bottom-5 left-5 w-12 h-12 z-30 hover:scale-110 transition"
    >
      <img
        src="/whatsapp.png"
        alt="Whatsapp button"
        className="w-12 h-12 shadow-lg rounded-full pointer-events-none select-none"
      />
    </a>
  );
};
