import { captureRemixErrorBoundaryError } from '@sentry/remix';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/cloudflare';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import './routes/reset.css';
import styles from './tailwind.css?url';
import './styles.css';
import { ParallaxProvider } from 'react-scroll-parallax';

import { IntercomProvider } from 'react-use-intercom';
import { ApplicationEnvironment } from './environment';
import { retry } from './retry';
import { useInitializeAmplitude } from './hooks/use-initialize-amplitude';
import { environmentState } from './state/environment';
import { getOrigin } from './get-origin';
import { keywords } from './seo/keywords';
import { propagateError } from './propagate-error';

import './sprites/sprites.svg';
import { Snackbar } from './components/snackbar/snackbar';
import { ErrorAnimation } from './components/404';
import { WhatsappButton } from './components/whatsapp/whatsapp-button';
import { GetInTouchModal } from './components/get-in-touch/get-in-touch-modal';
import { useState, useCallback, useEffect } from 'react';
import { Banner } from './components/banner/banner';
import { MobileNavigationDialog } from './components/camp67-header/mobile-navigation-dialog';
import { AmplitudeEvent, ButtonTag, analyticsState } from './state/amplitude';
import { Countries } from 'country-to-currency';
import { ExchangeRate, exchangeRate } from './state/prices';
import { reservationStore } from './state/reservation-store';
import i18nServer from './i18n/i18n.server';
import { useChangeLanguage } from 'remix-i18next/react';
import { FACEBOOK, INSTAGRAM, TWITTER, YOUTUBE } from './constants/socials';
import Sprite from './sprites/sprite';
export const handle = { i18n: ['translation'] };

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
];

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const env = context.cloudflare.env as ApplicationEnvironment;
  const requestCookie = request.headers.get('cookie') ?? '';

  try {
    const response = await retry('get-session', (signal) =>
      fetch(`${env.API_URL}/api/session`, {
        headers: { origin: getOrigin(request), Cookie: requestCookie },
        signal,
      }),
    );

    const countryCode: Countries =
      (response.headers.get('X-Country') as Countries) ?? 'USD';

    const prices = await response.json<ExchangeRate>();
    const locale = await i18nServer.getLocale(request);

    const keys = {
      amplitudeKey: env.AMPLITUDE_API_KEY,
      intercomKey: env.INTERCOM_APP_KEY,
      apiUrl: env.API_URL,
      paypalClientId: env.PAYPAL_CLIENT_ID,
      countryCode,
      prices,
      locale,
    };

    const cookie = response.headers.get('set-cookie');
    if (!cookie) {
      return json(keys);
    }

    return json(keys, {
      headers: {
        'Set-Cookie': cookie,
        'X-Country': response.headers.get('X-Country') ?? '',
      },
    });
  } catch (err) {
    propagateError(err);
    throw json('Internal Server Error', 500);
  }
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    // Classname, css modules, css class, none work, have to do style prop.
    <section
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorAnimation />
      <h1 style={{ fontFamily: 'Fraunces' }}>Oops! Something went wrong.</h1>
      <Link
        prefetch="intent"
        style={{ textDecoration: 'underline', fontFamily: 'DM Sans' }}
        to="/"
      >
        &larr; Go back
      </Link>
    </section>
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useRouteLoaderData<typeof loader>('root');

  return (
    <html lang={loaderData?.locale ?? 'en'}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="keywords" content={keywords.join(',')} />
        <meta httpEquiv="Content-language" content="en_US" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://imagedelivery.net" />
        <link rel="preconnect" href="https://api.amplitude.com" />
        <link rel="preconnect" href="https://widget.intercom.io" />
        <link rel="preconnect" href="https://js.intercomcdn.com" />

        {/* opengraph tags */}
        <meta
          property="og:title"
          content="camp67: Accommodation for the Kumbh Mela 2025"
        />
        <meta
          property="og:description"
          content="Book now for Kumbh Mela 2025. Affordable. Peaceful. Uniquely Kumbh. camp67 is the a premium location to stay during Kumbh Mela 2025. Book now!"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="camp67.com" />
        <meta property="og:url" content="https://camp67.com/" />
        <meta
          property="og:image"
          content="https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/eea87f85-a66c-4282-aaa8-ca11bec94400/public"
        />
        <meta property="og:image:type" content="image/avif" />
        <meta property="og:type" content="website" />

        {/* twitter tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@camp67_kumbh" />
        <meta
          name="twitter:title"
          content="camp67: Accommodation for the Kumbh Mela 2025"
        />
        <meta
          name="twitter:description"
          content="Book now for Kumbh Mela 2025. Affordable. Peaceful. Uniquely Kumbh."
        />
        <meta
          name="twitter:image"
          content="https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/eea87f85-a66c-4282-aaa8-ca11bec94400/public"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <ParallaxProvider>
          <IntercomProvider
            appId={loaderData?.intercomKey ?? ''}
            initializeDelay={2_000}
            autoBoot={true}
          >
            {children}
            <ScrollRestoration />
            <Scripts />
          </IntercomProvider>
        </ParallaxProvider>
      </body>
      <MobileNavigationDialog />
    </html>
  );
}

export default function App() {
  const { amplitudeKey, apiUrl, paypalClientId, countryCode, prices, locale } =
    useLoaderData<typeof loader>();

  useInitializeAmplitude(amplitudeKey);

  environmentState.apiUrl = apiUrl;
  environmentState.paypalClientId = paypalClientId;
  environmentState.countryCode = countryCode;

  reservationStore.initializeCountryCode(countryCode);

  if (prices.currency) {
    exchangeRate.currency = prices.currency;
    exchangeRate.rate = prices.rate;
    exchangeRate.symbol = prices.symbol;
  }

  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => setIsOpen(false), []);

  const onOpenInquiry = useCallback(() => {
    analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonTag: ButtonTag.OPEN_INQUIRY,
    });
    setIsOpen(true);
  }, []);

  useChangeLanguage(locale);

  return (
    <>
      <Banner onOpenInquiry={onOpenInquiry} />
      <Snackbar />
      <WhatsappButton />
      <GetInTouchModal isOpen={isOpen} onClose={onClose} />
      <Socials />
      <Outlet />
    </>
  );
}

const Socials = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const footer = document.querySelector('#footer');

    if (footer) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsFooterVisible(entry.isIntersecting);
        },
        { threshold: 0.1 },
      );

      observer.observe(footer);

      return () => {
        observer.unobserve(footer);
      };
    }
  }, []);

  useEffect(() => {
    const banner = document.querySelector('#camp67-banner');

    if (banner) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsBannerVisible(entry.isIntersecting);
        },
        { threshold: 0.1 },
      );

      observer.observe(banner);

      return () => {
        observer.unobserve(banner);
      };
    }
  }, []);

  if (location.pathname.includes('reservation')) {
    return null;
  }

  if (isFooterVisible || isBannerVisible) return null;

  return (
    <div className="sm:flex hidden flex-col fixed right-0 top-1/2 -translate-y-1/2 bg-white rounded-l-lg px-1 py-3 shadow-lg z-20">
      <ul className="flex flex-col list-none gap-3 xs:pt-0 items-center">
        <li>
          <a href={FACEBOOK} target="_blank" rel="noreferrer">
            <Sprite name="facebook" width="64" height="64" className="w-6 h-6" />
          </a>
        </li>
        <li>
          <a href={INSTAGRAM} target="_blank" rel="noreferrer">
            <img
              src="/instagram.png"
              alt="Instagram"
              className="pointer-events-none select-none w-6 h-6"
            />
          </a>
        </li>
        <li>
          <a href={TWITTER} target="_blank" rel="noreferrer">
            <Sprite
              name="twitter"
              width="64"
              height="64"
              className="w-4 h-4 md:w-6 md:h-6"
            />
          </a>
        </li>
        <li>
          <a href={YOUTUBE} target="_blank" rel="noreferrer">
            <Sprite
              name="youtube"
              width="64"
              height="45"
              className="w-4 h-4 md:w-6 md:h-6"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};
